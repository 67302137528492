/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from 'react';
import axios from '../../configuraciones/axios';
import propTypes from 'prop-types';
import { Grid, useMediaQuery } from '@material-ui/core';
import moment from 'moment';

import endpoints from '../../configuraciones/endpoints';
import estatusPago from '../../constantes/estatusInvoice';
import { obtenerIntervaloStripe } from '../../utilidades/functions';
import TablaInfinita from '../TablaInfinita';
import Estatus from '../Estatus';

const ClientePagos = ({ cliente, idTamanoPadre, porcentajeAltura }) => {
  const { stripeCustomerId } = cliente;
  const formatoFecha = 'DD MMMM YYYY';
  const match = useMediaQuery((theme) => theme.breakpoints.only('xs'));

  const [height, setHeight] = useState(0);
  const [cargandoPagos, setCargandoPagos] = useState(false);
  const [pagos, setPagos] = useState(null);

  const cabeceros = useMemo(() => [
    {
      label: 'PERIODO DE PAGO',
      transform: ({ lines }) =>
        `Pago ${moment.unix(lines.data[lines.data.length - 1]?.period.start).format(formatoFecha)} - ${moment.unix(lines.data[lines.data.length - 1]?.period.end).format(formatoFecha)}: ${obtenerIntervaloStripe(lines.data[lines.data.length - 1]?.plan.interval)}`,
      width: '40%',
    },
    {
      label: 'FECHA',
      transform: ({ status_transitions }) =>
        `${moment.unix(status_transitions.paid_at).format(formatoFecha)}`,
      width: '20%',
    },
    {
      label: 'ESTATUS',
      transform: ({ status }) =>
        <Estatus
          label={estatusPago[status].leyendaEstatus}
          backgroundColor={estatusPago[status].colorFondo}
        />,
      width: '20%',
    },
    {
      label: 'MONTO',
      transform: ({ total, currency }) =>
        `$${total / 100} ${currency.toUpperCase()}`,
      width: '20%',
    }
  ], []);

  useEffect(() => {
    pagosIniciales();
  }, [stripeCustomerId]);

  useEffect(() => {
    setTimeout(() => {
      const valor = (document.getElementById(idTamanoPadre)?.clientHeight * porcentajeAltura) || 0;
      setHeight(valor);
    }, 100);
  }, [idTamanoPadre, porcentajeAltura]);

  const pagosIniciales = () => {
    if (stripeCustomerId) {
      axios
        .get(endpoints.pagosPorCliente(), {
          params: {
            stripeCustomerId,
          },
        })
        .then((pagosBd) => setPagos(pagosBd));
    }
  };

  const cargarPagosSiguientes = useCallback(() => {
    setCargandoPagos(true);

    if (stripeCustomerId) {
      axios
        .get(endpoints.pagosPorCliente(), {
          params: {
            stripeCustomerId,
            nextPage: pagos?.next_page,
          }
        })
        .then((pagosBd) => {
          if (pagosBd) {
            pagosBd.data = [
              ...pagos?.data,
              ...pagosBd.data
            ];
          }

          setPagos(pagosBd);
        })
        .finally(() => setCargandoPagos(false));
    }
  }, [stripeCustomerId, pagos?.next_page, pagos?.data]);

  return (
    <Grid container spacing={2} style={{ height: match ? 0 : height }}>
      <Grid item xs={12}>
        <TablaInfinita
          headers={cabeceros}
          showActions={false}
          rows={pagos?.data}
          hasNextPage={pagos?.has_more}
          isNextPageLoading={cargandoPagos}
          loadNextPage={cargarPagosSiguientes}
        />
      </Grid>
    </Grid>
  );
};

ClientePagos.propTypes = {
  /** Objeto que contiene la informacion del cliente */
  cliente: propTypes.shape({
    /** Id de stripe del cliente */
    stripeCustomerId: propTypes.string.isRequired
  }).isRequired,
};

ClientePagos.defaultProps = {
  porcentajeAltura: 0.85
}

export default ClientePagos;
