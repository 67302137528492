import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  texto: {
    color: '#8A9FBB',
    opacity: 0.6,
  },
  titulo: {
    fontSize: 45,
    textTransform: 'uppercase',
  },
  label: {
    fontSize: 18,
  },
  link: {
    color: '#3F8CFF',
    textDecoration: 'none',
  }
}));

export default useStyles;
