/* eslint-disable import/no-anonymous-default-export */

const SET_PAGINA = 'SET_PAGINA';
const SET_REGISTROS = 'SET_REGISTROS';
const CLEAR_REGISTROS = 'CLEAR_REGISTROS';

const SET_SITIOS = 'SET_SITIOS';
const SET_INVERNADEROS = 'SET_INVERNADEROS';
const SET_NAVES = 'SET_NAVES';

const initialState = {
  pagina: 1,
  registros: { rows: [], count: 0 },
  sitios: [],
  invernaderos: [],
  naves: [],
};

export default (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case SET_PAGINA:
      return { ...state, pagina: payload };
    case SET_REGISTROS:
      return { ...state, registros: payload };
    case CLEAR_REGISTROS:
      return { ...state, registros: initialState.registros, pagina: initialState.pagina };
    case SET_SITIOS:
      return { ...state, sitios: payload };
    case SET_INVERNADEROS:
      return { ...state, invernaderos: payload };
    case SET_NAVES:
      return { ...state, naves: payload };
    default:
      return state;
  }
};

export const setPagina = (payload) => ({ type: SET_PAGINA, payload });
export const setRegistros = (payload) => ({ type: SET_REGISTROS, payload });
export const setLimpiar = () => ({ type: CLEAR_REGISTROS });

