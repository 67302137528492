import React from 'react';
import propTypes from 'prop-types';
import { Button as Boton, Box, Tooltip } from '@material-ui/core';
import clsx from 'clsx';

import Typography from '../Typography';

import styles from './styles';

const Button = ({
  label, name, icono, classesCustom, onClick, border, isSubmit, value, tooltipMsj
}) => {
  const classes = styles();
  return (
    <div className={classes.root}>
      <Tooltip title={tooltipMsj}>
        <Boton
          className={clsx(classes.boton, classesCustom.boton, classesCustom.active, { [classes.border]: border })}
          name={name}
          onClick={onClick}
          type={isSubmit ? 'submit' : 'button'}
          value={value}
        >
          <Box flex={1} className={classes.box}>
            {icono}
            {label !== '' && <Typography className={clsx({ icono: Boolean(icono) })}>{label}</Typography>}
          </Box>
        </Boton>
      </Tooltip>
    </div>
  );
};

Button.propTypes = {
  label: propTypes.string,
  name: propTypes.string,
  icono: propTypes.element,
  estilo: propTypes.object,
  classesCustom: propTypes.shape({
    boton: propTypes.string,
    icono: propTypes.string,
    texto: propTypes.string,
  }),
  onClick: propTypes.func,
  border: propTypes.bool,
  isSubmit: propTypes.bool,
  value: propTypes.oneOfType([
    propTypes.string,
    propTypes.number,
  ]),
  tooltipMsj: propTypes.string,
}

Button.defaultProps = {
  label: '',
  variant: 'contained',
  icono: null,
  classesCustom: {},
  onClick: null,
  border: false,
  isSubmit: false,
  value: '',
  tooltipMsj: '',
}

export default React.memo(Button);