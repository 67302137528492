import React, { useState, useCallback } from 'react';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import md5 from 'md5';
import TextField from '../../componentes/TextField';
import Button from '../../componentes/Button';
import Footer from '../../componentes/Footer';
import FormProvider from '../../FormProvider';
import { setUser, STORAGE } from '../../ducks/user';
import endpoints from '../../configuraciones/endpoints';
import axios, { setTokenHeader } from '../../configuraciones/axios';
import styles from './styles';

const Login = () => {
  const dispatch = useDispatch();
  const classes = styles();
  const [usuario, setUsuario] = useState({ nombreCorto: '', password: '', mantenerSesion: false });
  const [formErrors, setFormErrors] = useState({});

  /** Funcion que se ejecuta cuando hacen click en el checkbox de recordar crendenciales */
  const handleCheckboxChecked = useCallback((e) => {
    setUsuario((current) => ({ ...current, mantenerSesion: e.target.checked }));
  }, []);

  /** Funcion para iniciar sesion */
  const iniciarSesion = (e) => {
    let usuarioLogin = {
      nombreCorto: usuario.nombreCorto,
      contrasena: md5(usuario.password),
    };

    axios.post(endpoints.iniciarSesion(), usuarioLogin).then((sesion) => {
      if (usuario.mantenerSesion)
        localStorage.setItem(STORAGE, JSON.stringify(sesion));
      else
        sessionStorage.setItem(STORAGE, JSON.stringify(sesion));

      setTokenHeader(sesion.token);
      dispatch(setUser(sesion));
    });
  }

  return (
    <Grid container className={classes.main}>
      <Grid item xs={12}>
        <Grid container className={classes.body} direction="row-reverse">
          <Grid item xs={12} md={4}>
            <FormProvider onSubmit={iniciarSesion} onChangeErrors={setFormErrors}>
              <Grid container spacing={1} className={classes.card} id="card">
                <Grid item xs={12}>
                  <h2>INICIO DE SESIÓN</h2>
                  <p>Ingrese sus credenciales para identificarse</p>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent='center'>
                    <img src='/assets/logo.svg' alt='icono' className={classes.logoDelCampo} />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name='nombreCorto'
                    placeHolder="Usuario"
                    value={usuario.nombreCorto}
                    onChange={setUsuario}
                    isHandleChange
                    required
                    error={formErrors.nombreCorto}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name='password'
                    placeHolder="Contraseña"
                    type="password"
                    value={usuario.password}
                    onChange={setUsuario}
                    isHandleChange
                    required
                    error={formErrors.password}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel control={
                    <Checkbox
                      name='MantenerSesion'
                      value={usuario.mantenerSesion}
                      onChange={handleCheckboxChecked} />
                  }
                    label="Recordar mis credenciales" />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    name="iniciar"
                    label="Iniciar"
                    classesCustom={{ boton: classes.botonInicio }}
                    isSubmit
                  />
                </Grid>
              </Grid>
            </FormProvider>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Footer />
      </Grid>
    </Grid >
  );
};

export default Login;
