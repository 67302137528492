import propTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import {
  makeStyles, Paper, Grid,
  useMediaQuery, Box,
} from '@material-ui/core';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import axios from '../../configuraciones/axios';

import Header from './Header';
import Mensaje from './Mensaje';
import Input from './Input';
import Burbuja from './Burbuja';
import useSocket from '../../hooks/useSocket';
import endpoints from '../../configuraciones/endpoints';
import useOnScreen from '../../hooks/useOnScreen';

const useStyles = makeStyles(() => ({
  root: {
    border: '1px solid #E6EBF5',
    borderRadius: 24,
    minHeight: 400,
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  mensajesContainer: {
    padding: 15,
    overflowY: 'scroll',
  },
}));

const Chat = ({ idTamanoPadre, cliente, porcentajeAltura, tipoMensaje }) => {
  const styles = useStyles();
  const listRef = useRef(null);
  const match = useMediaQuery((theme) => theme.breakpoints.only('xs'));
  const [height, setHeight] = useState(0);
  const [items, setItems] = useState({ count: 0, rows: [] });
  const socket = useSocket(cliente.id);
  const ref = useRef(null);
  const esVisible = useOnScreen(ref);

  const Row = ({ index, style }) => (
    <Grid item xs={12} style={style}>
      <Mensaje mensaje={items.rows[index]} />
    </Grid>
  );

  useEffect(() => {
    setTimeout(() => {
      const valor = (document.getElementById(idTamanoPadre)?.clientHeight * porcentajeAltura) || 0;
      setHeight(valor);
    }, 100);
  }, [idTamanoPadre, porcentajeAltura]);

  useEffect(() => {
    if (esVisible) {
      socket.emit('visto', {
        clienteId: cliente?.id,
        tipoMensaje,
      });
    }
  }, [esVisible, cliente, socket, tipoMensaje]);

  useEffect(() => {
    if (socket && cliente?.id) {
      socket.on(`cliente-${cliente.id}`, (accion, mensaje) => {
        switch (accion) {
          case 'push_mensaje':
            setItems((current) => ({
              count: current.count + 1,
              rows: current.rows.concat([mensaje]),
            }));
            break;

          default:
            break;
        }
      });
    }
  }, [socket, cliente]);

  useEffect(() => {
    listRef.current?.scrollToItem(items.count - 1);
  }, [items]);

  const consultarDatosIniciales = () => new Promise((resolve, reject) => {
    const promesas = [];
    if (cliente?.id)
      promesas.push(axios.get(endpoints.chat(cliente?.id), { params: { noajax: true } }))
    if (promesas.length === 0) return;

    Promise.all(promesas).then((resultadosPromesas) => {
      const [chat] = resultadosPromesas;
      setItems(chat);
      resolve();
    }).catch(reject);
  });

  useEffect(() => {
    consultarDatosIniciales();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cliente]);

  return (
    <Paper className={styles.root} style={{ height: match ? 0 : height }} ref={ref}>
      <Header
        avatar={cliente.logo}
        nombre={cliente.nombre}
        correo={cliente.correo}
      />
      <Box flex={1} className={styles.mensajesContainer}>
        <Grid container spacing={2} style={{ height: '100%' }}>
          <Grid item xs={12}>
            <AutoSizer>
              {({ height, width }) => (
                <List
                  className="List"
                  height={height}
                  width={width}
                  itemCount={items.count}
                  itemSize={100}
                  ref={listRef}
                >
                  {Row}
                </List>
              )}
            </AutoSizer>
          </Grid>
        </Grid>
      </Box>
      <Input cliente={cliente} tipoMensaje={tipoMensaje} />
    </Paper>
  );
};

Chat.propTypes = {
  /** Identificador de un elemento a tomar como referencia para el tamaño en altura */
  idTamanoPadre: propTypes.string,
  /** Objeto que contiene la informacion del cliente */
  cliente: propTypes.object.isRequired,
  /** Indica el porcentaje de altura en funcion del tamaño del padre */
  porcentajeAltura: propTypes.number,
  /** Indica el tipo de mensaje (entrada / salida) */
  tipoMensaje: propTypes.oneOf(['E', 'S']),
};

Chat.defaultProps = {
  porcentajeAltura: 0.85,
  tipoMensaje: 'S',
}

export default Chat;

export {
  Burbuja,
};