const estatusInvoice = {
  draft: {
    leyendaEstatus: 'Preparando cobro',
    colorFondo: '#faf36b',
  },
  open: {
    leyendaEstatus: 'Pendiente',
    colorFondo: '#faf36b',
  },
  paid: {
    leyendaEstatus: 'Exitoso',
    colorFondo: '#88fa6b',
  },
  void: {
    leyendaEstatus: 'Cancelado',
    colorFondo: '#fa6b6b',
  },
  uncollectible: {
    leyendaEstatus: 'Fallido',
    colorFondo: '#fa6b6b',
  }
};

export default estatusInvoice;
