import React from 'react';
import { AppBar, Grid } from '@material-ui/core';
import styles from './styles';

const Footer = () => {
  const classes = styles();

  return (
    <AppBar className={classes.appBar}>
      <Grid container className={classes.footer}>
        <Grid item>
          <img src='/assets/logo2.svg' alt='icono' width='35' height='35' />
        </Grid>
        <Grid item>
          <Grid container alignItems='baseline'>
            <h1>&nbsp;ProvettaSoft&nbsp;</h1>
            <h2>ADMON</h2>
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default Footer;
