import { useState, useEffect, useCallback, memo } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Default from '../../contenedores/Default';
import PaperContainer from '../../componentes/PaperContainer';
import Typography from '../../componentes/Typography';
import TextField from '../../componentes/TextField';
import Chat, { Burbuja } from '../../componentes/Chat';
import useSocket from '../../hooks/useSocket';
import axios from '../../configuraciones/axios';
import endpoints from '../../configuraciones/endpoints';
import { removerNotificacion } from '../../ducks/user';

const Conversaciones = () => {
  const [clientes, setClientes] = useState([]);
  const [clienteSeleccionado, setClienteSeleccionado] = useState(null);
  const [txtBusqueda, setTxtBusqueda] = useState('');
  const notificaciones = useSelector((store) => store.user.notificaciones);
  const socket = useSocket('LAB');
  const dispatch = useDispatch();

  const seleccionarCliente = useCallback((index, cliente) => {
    setClienteSeleccionado(cliente);
    setClientes((current) => {
      const updatedData = [...current];
      updatedData[index]['notificacion'] = '0';
      return updatedData;
    })
  }, [])

  const consultarDatos = useCallback((textoBusqueda = '', noajax = false) => new Promise((resolve, reject) => {
    const promesas = [];
    promesas.push(
      axios.get(endpoints.clientesChat(), { params: { textoBusqueda, noajax } })
    );

    Promise.all(promesas).then((resultadosPromesas) => {
      const [clientesBd] = resultadosPromesas;
      setClientes(clientesBd);
      resolve();
    }).catch(reject);
  }), []);

  const buscar = useCallback(({ keyCode }) => {
    if (keyCode === 13) {
      consultarDatos(txtBusqueda);
    }
  }, [consultarDatos, txtBusqueda]);

  useEffect(() => {
    consultarDatos();
  }, [consultarDatos]);

  useEffect(() => {
    if (!socket) return;
    socket.on('mensaje_recibido', () => {
      consultarDatos(txtBusqueda, true);
    });
  }, [socket, consultarDatos, txtBusqueda]);

  useEffect(() => {
    if (clienteSeleccionado)
      dispatch(removerNotificacion(clienteSeleccionado.id));
  }, [clienteSeleccionado, dispatch]);

  useEffect(() => {
    if (notificaciones.includes(clienteSeleccionado?.id))
      dispatch(removerNotificacion(clienteSeleccionado.id));
  }, [notificaciones, clienteSeleccionado, dispatch]);

  return <Default
    titulo={''}
    placeHolder={''}
  >
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4">Clientes</Typography>
              </Grid>
              <Grid item xs={12}>
                <PaperContainer idTamanoPadre="root" porcentajeAltura={0.82}>
                  {clientes.map((cliente, index) => (
                    <Burbuja
                      key={index}
                      cliente={cliente}
                      onClick={(c) => seleccionarCliente(index, c)}
                    />
                  ))}
                </PaperContainer>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={9}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container justifyContent='flex-end'>
                  <Grid item xs={3}>
                    <TextField
                      placeHolder="ID/SUSCRIPTOR"
                      value={txtBusqueda}
                      onChange={({ target: { value } }) => setTxtBusqueda(value)}
                      onKeyDown={buscar}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {clienteSeleccionado && <Chat
                  cliente={clienteSeleccionado}
                  idTamanoPadre="root"
                  porcentajeAltura={0.79}
                  tipoMensaje="S"
                />}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Default>
};

export default memo(Conversaciones);
