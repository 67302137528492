import React from 'react';
import propTypes from 'prop-types';
import clsx from 'clsx';
import { Box } from '@material-ui/core';
import Typography from '../Typography';
import styles from './styles';

const Estatus = ({ activo, label, backgroundColor }) => {
  const classes = styles();

  return (
    <Box
      className={clsx(classes.root, { disabled: !activo })}
      style={backgroundColor && { backgroundColor }}
    >
      <Typography
        className={clsx(classes.textoEstatus)}
      >
        {!label && (activo ? 'Habilitado' : 'Inhabilitado')}
        {label && label}
      </Typography>
    </Box>
  )
};

Estatus.propTypes = {
  /** Flag que indica si mostrar el label habilitado/deshabilitado */
  activo: propTypes.bool,
  /** Label a mostrar */
  label: propTypes.string,
  /** Color de fondo a mostrar */
  backgroundColor: propTypes.string,
};

export default React.memo(Estatus);