import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Grid, useMediaQuery, TextField as TextFieldMui } from '@material-ui/core';

import axios from '../../configuraciones/axios';
import endpoints, { STRIPE_INVOICES } from '../../configuraciones/endpoints';
import Default from '../../contenedores/Default';
import TablaInfinita from '../../componentes/TablaInfinita';
import Typography from '../../componentes/Typography';
import TextField from '../../componentes/TextField';
import { obtenerIntervaloStripe } from '../../utilidades/functions';
import styles from './styles';
import { Autocomplete } from '@material-ui/lab';

const Pagos = () => {
  const formatoFecha = 'DD MMM YYYY';
  const idTamanoPadre = 'main';
  const porcentajeAltura = 0.85;
  const classes = styles();
  const match = useMediaQuery((theme) => theme.breakpoints.only('xs'));

  const [height, setHeight] = useState(0);
  const [cargandoPagos, setCargandoPagos] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [planes, setPlanes] = useState([]);
  const [txtFolio, setTxtFolio] = useState('');
  const [txtFolioAnterior, setTxtFolioAnterior] = useState('');
  const [clienteSeleccionado, setClienteSeleccionado] = useState(null);
  const [planSeleccionado, setPlanSeleccionado] = useState(null);
  const [pagos, setPagos] = useState(null);

  const cabeceros = useMemo(() => [
    {
      label: 'FECHA',
      transform: ({ status_transitions }) =>
        `${moment.unix(status_transitions.paid_at).format(formatoFecha)}`,
      width: '15%',
    },
    {
      label: 'FOLIO',
      key: 'number',
      width: '15%',
    },
    {
      label: 'CLIENTE/SUSCRIPTOR',
      transform: ({ metadata, customer, customer_name }) => `${metadata["clienteId"] || customer} ${customer_name ? "- " + customer_name : ''}`,
      width: '20%',
    },
    {
      label: 'PLAN',
      transform: ({ metadata, lines }) => `${metadata["product_name"] || '- - - '} ${obtenerIntervaloStripe(lines.data[lines.data.length -1]?.plan?.interval)}`,
      width: '15%',
    },
    {
      label: 'MONTO',
      transform: ({ total, currency }) =>
        `$${total / 100} ${currency.toUpperCase()}`,
      width: '15%',
    },
    {
      label: 'PERIODO PAGADO',
      transform: ({ period_start, period_end, lines }) =>
        `Pago ${moment.unix(lines?.data[lines.data.length -1]?.period?.start).format(formatoFecha)} - ${moment.unix(lines?.data[lines.data.length -1]?.period?.end).format(formatoFecha)}`,
      width: '20%',
    },
  ], []);

  useEffect(() => {
    cargarDatosIniciales();

    setTimeout(() => {
      const valor = (document.getElementById(idTamanoPadre)?.clientHeight * porcentajeAltura) || 0;
      setHeight(valor);
    }, 100);
  }, []);

  useEffect(() => {
    pagosIniciales();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [txtFolioAnterior, clienteSeleccionado, planSeleccionado]);

  const cargarDatosIniciales = () => {
    const promesas = [];

    promesas.push(axios.get(endpoints.clientesAutocomplete()));
    promesas.push(axios.get(endpoints.planesAutocomplete()));

    Promise.all(promesas).then((resultadosPromesas) => {
      let [clientesBd, planesBd] = resultadosPromesas;
      setClientes(clientesBd);
      setPlanes(planesBd);
    });
  };

  const pagosIniciales = () => {
    axios
      .get(endpoints.base.url(STRIPE_INVOICES), {
        params: {
          invoiceNumber: txtFolio,
          customer: clienteSeleccionado?.stripeCustomerId,
          product: planSeleccionado?.stripeId,
        }
      })
      .then((pagosBd) => setPagos(pagosBd));
  };

  const cargarPagosSiguientes = useCallback(() => {
    setCargandoPagos(true);

    axios
      .get(endpoints.base.url(STRIPE_INVOICES), {
        params: {
          invoiceNumber: txtFolio,
          customer: clienteSeleccionado?.stripeCustomerId,
          product: planSeleccionado?.stripeId,
          nextPage: pagos?.next_page,
        }
      })
      .then((pagosBd) => {
        if (pagosBd) {
          pagosBd.data = [
            ...pagos?.data,
            ...pagosBd.data
          ];
        }
  
        console.log(pagosBd);
        setPagos(pagosBd);
      })
      .finally(() => setCargandoPagos(false));
  }, [clienteSeleccionado?.stripeCustomerId, pagos?.data, pagos?.next_page, planSeleccionado?.stripeId, txtFolio]);

  const manejadorKeyDown = (e) => {
    if (e.key === "Enter") {
      if (txtFolioAnterior !== txtFolio) {
        setTxtFolioAnterior(txtFolio);
      }
    }
  };

  return (
    <Default>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item lg={6} md={3} sm={12} xs={12}>
              <Typography variant="h5">Pagos</Typography>
            </Grid>
            <Grid item lg={2} md={3} sm={12} xs={12}>
              <TextField
                name="txtFolio"
                placeHolder={'FOLIO'}
                className={classes.textField}
                inputClassName={classes.textFieldInput}
                onChange={({ target: { value } }) => setTxtFolio(value)}
                value={txtFolio}
                onKeyDown={manejadorKeyDown}
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
              />
            </Grid>
            <Grid item lg={2} md={3} sm={12} xs={12}>
              <Autocomplete
                id="combo-box-clientes"
                options={clientes}
                getOptionLabel={(option) => option.nombre}
                size='small'
                onChange={(event, value) => setClienteSeleccionado(value)}
                renderInput={(params) => (
                  <TextFieldMui
                    {...params}
                    label="SUSCRIPTOR"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item lg={2} md={3} sm={12} xs={12}>
              <Autocomplete
                id="combo-box-planes"
                options={planes}
                getOptionLabel={(option) => option.nombre}
                size='small'
                onChange={(event, value) => setPlanSeleccionado(value)}
                renderInput={(params) => (
                  <TextFieldMui
                    {...params}
                    label="PLAN"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4} style={{ height: match ? 0 : height }}>
            <Grid item xs={12}>
              <TablaInfinita
                headers={cabeceros}
                showActions={false}
                rows={pagos?.data}
                hasNextPage={pagos?.has_more}
                isNextPageLoading={cargandoPagos}
                loadNextPage={cargarPagosSiguientes}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Default>
  );
};

export default React.memo(Pagos);