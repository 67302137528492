import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import clsx from 'clsx';
import Typography from '../Typography';
import Estatus from '../Estatus';
import useStyles from './styles';
import axios from '../../configuraciones/axios';
import endpoints, { STRIPE_CUSTOMERS } from '../../configuraciones/endpoints';

const ClienteSuscripcion = ({ cliente }) => {
  const [clienteStripe, setClienteStripe] = useState({ suscripcions: [] });
  const styles = useStyles();
  const formatoFecha = 'DD MMMM YYYY';
  const { stripeCustomerId, suscripcions: [suscripcion] } = cliente;

  const consultarClienteStripe = () => new Promise((resolve, reject) => {
    const promesas = [];
    promesas.push(axios.get(endpoints.base.url(STRIPE_CUSTOMERS, stripeCustomerId)));
    Promise.all(promesas).then((resultadosPromesas) => {
      const [clienteStripe] = resultadosPromesas;
      setClienteStripe(clienteStripe);
      resolve();
    }).catch(reject);
  });

  useEffect(() => {
    if (stripeCustomerId)
      consultarClienteStripe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripeCustomerId]);

  return (
    <Grid container spacing={8}>
      <Grid item xs={12}>
        <Typography className={clsx(styles.titulo, styles.texto)} bold>
          {clienteStripe?.subscriptions?.data[0]?.status === 'trialing' ? 'PLAN DEMO' : suscripcion?.plan?.nombre}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography className={clsx(styles.texto, styles.label)} bold>
                  VIGENCIA DEL PERIODO
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {moment(suscripcion?.fechaInicio).format(formatoFecha)}
                {' - '}
                {moment(suscripcion?.fechaFin).format(formatoFecha)}
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography className={clsx(styles.texto, styles.label)} bold>
                  PERIODO
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {`${moment(suscripcion?.fechaFin).diff(moment(suscripcion?.fechaInicio), 'days')} días`}
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography className={clsx(styles.texto, styles.label)} bold>
                  ESTADO
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Estatus label={suscripcion?.estatus?.nombre} backgroundColor={suscripcion?.estatus?.color} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={clsx(styles.texto, styles.label)} bold>
              SISTEMA
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <a href={`https://${cliente.subDominio}`} className={styles.link}>
              {cliente.subDominio}
            </a>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ClienteSuscripcion.propTypes = {
  /** Objeto que contiene la informacion del cliente */
  cliente: propTypes.shape({
    /** array que contiene la informacion del plan */
    suscripcions: propTypes.array.isRequired,
  }).isRequired,
};

export default ClienteSuscripcion;
