import { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { useJwt } from 'react-jwt';
import { useDispatch } from 'react-redux';
import { setTokenHeader } from '../../configuraciones/axios';
import { setSoloToken } from '../../ducks/user';
import Chat from '../../componentes/Chat';

const ChatEmb = ({ match }) => {
  const { params: { id } } = match;
  setTokenHeader(id);
  const dispatch = useDispatch();
  const { decodedToken, } = useJwt(id);

  useEffect(() => {
    dispatch(setSoloToken(id));
    document.documentElement.style.height = "100%";
    document.body.style.height = "100%";
    document.body.style.margin = "0";
    document.body.style.backgroundColor = "transparent";
    document.getElementById('root').style.height = "100%";
    document.getElementById('root').style.display = "flex";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setSoloToken(id));
  }, [dispatch, id]);

  if (!decodedToken) return null;

  return (
    <Box flex={1}
      alignItems="center"
      justifyContent="center"
      display="flex"
      style={{ padding: 15 }}
      id="box"
    >
      <Chat tipoMensaje="E" cliente={decodedToken || {}} idTamanoPadre="box" porcentajeAltura={0.75} />
    </Box>
  );
}

export default ChatEmb;
