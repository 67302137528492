import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  root: {
    backgroundColor: paleta.bar.user,
    borderRadius: 10,
    padding: theme.spacing(0.5, 1),
    userSelect: 'none',
    '&.disabled': {
      backgroundColor: paleta.activo.primary,
    },
    width: 'fit-content',
  },
  textoEstatus: {
    mixBlendMode: 'luminosity',
  }
}));

export default styles;
