import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Box, Grid } from '@material-ui/core';

import styles from './styles';

export const TabsHeader = React.memo((props) => {
  const { tabs, onChangeActive } = props;
  const classes = styles();
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = useCallback((e, index) => {
    setActiveTab(index);
    onChangeActive && onChangeActive(index);
  }, [onChangeActive]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center">
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={activeTab}
            onChange={handleChange}
            classes={{ root: classes.tabsRoot }}
            TabIndicatorProps={{ className: classes.indicator }}
          >
            {
              tabs.map((element) => (
                <Tab
                  disableRipple
                  key={element.id}
                  label={element?.label}
                  classes={{ root: classes.tabsRoot }}
                />
              ))
            }
          </Tabs>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <div
          role="tabpanel"
          style={{ width: '100%', height: '100%' }}
        >
          {
            tabs.map((element, index) => (
              <Box height="100%" key={index} hidden={index !== activeTab}>
                {element.children}
              </Box>
            ))
          }
        </div>
      </Grid>
    </Grid>
  );
});

TabsHeader.propTypes = {
  /** Valor Seleccionado */
  value: PropTypes.number,
  /** Función para cambiar de valor */
  handleChange: PropTypes.func,
  /** Arreglo con los tabs a mostrar */
  tabs: PropTypes.arrayOf(PropTypes.shape({
    /** Identificador y posición del tab */
    id: PropTypes.number,
    /** Nombre del tab */
    label: PropTypes.string,
  })),
  /** Funcion callback cuando cambia la pestaña activa */
  onChangeActive: PropTypes.func,
};

TabsHeader.defaultProps = {
  tabs: [],
  value: 0,
  handleChange: () => { },
  onChangeActive: null,
};

export const TabContainer = ((props) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      style={{ width: '100%', height: '100%' }}
      {...other}
    >
      <Box display={value === index ? 'block' : 'none'} height="100%">{children}</Box>
    </div>
  );
});

TabContainer.propTypes = {
  /** Valor Seleccionado */
  value: PropTypes.number,
  /** Index */
  index: PropTypes.number,
  /** Función para cambiar de valor */
  children: PropTypes.node,
};

TabContainer.defaultProps = {
  value: 0,
  index: 0,
  children: null,
};