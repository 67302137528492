import React from 'react';
import { Backdrop } from '@material-ui/core';
import { BeatLoader } from 'react-spinners';
import { useSelector } from 'react-redux';
import paleta from '../../configuraciones/paleta';
import styles from './styles';

const Spinner = () => {
  const classes = styles();
  const ajax = useSelector(({ ajax }) => ajax);
  if (ajax > 0)
    return (
      <Backdrop
        className={classes.root}
        open={true}
      >
        <BeatLoader
          color={paleta.bar.primary}
          size={32} />
      </Backdrop>
    );
  else
    return null;
};

export default Spinner;
