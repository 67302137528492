import React, { useCallback, useState, } from 'react';
import {
  AppBar as MaterialBar, Toolbar,
  Box, Tabs, Tab, IconButton, Button,
  Menu, MenuItem, Hidden, Badge
} from '@material-ui/core';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineBell } from 'react-icons/ai';
import { RiArrowDownSFill } from 'react-icons/ri';
import isEqual from 'lodash/isEqual';

import Typography from '../../componentes/Typography';
import { MENU_OPCIONES, quitarPermisos, setOpcionActiva } from '../../ducks/appbar';
import { removeToken } from '../../ducks/user';
import paleta from '../../configuraciones/paleta';

import styles from './styles';

const AppBar = () => {
  const classes = styles();
  const { indexActivo, user, ocultarOpciones, notificaciones } = useSelector(
    ({
      appbar: { indexActivo, ocultarOpciones },
      user: { user, notificaciones }
    }) => ({ indexActivo, user, ocultarOpciones, notificaciones }),
    (prev, next) => {
      return (
        prev.indexActivo === next.indexActivo &&
        prev.ocultarOpciones === next.ocultarOpciones &&
        isEqual(prev.permisos, next.permisos) &&
        prev.user.perfilID === next.user.perfilID &&
        prev.user.nombre === next.user.nombre &&
        prev.notificaciones === next.notificaciones
      );
    }
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const [userAnchor, setUserAnchor] = useState(null);

  const handleClick = useCallback((opcion) => {
    sessionStorage.setItem('tabIndex', opcion.index);
    dispatch(setOpcionActiva(opcion.index));
    history.replace(opcion.path)
  }, [dispatch, history]);

  const cerrarSesion = useCallback(() => {
    dispatch(removeToken());
    dispatch(quitarPermisos());
    sessionStorage.clear();
    localStorage.clear();
  }, [dispatch]);

  return (
    <div>
      <MaterialBar position="static" className={classes.root}>
        <Toolbar>
          <Box flex={1} style={{ height: '64px', display: 'flex' }} flexDirection="row">
            <Box className={classes.logoContainer}>
              <img
                alt="logo"
                src="/assets/logo2.svg"
                className={classes.logo} />
            </Box>
            {!ocultarOpciones && <Hidden smDown>
              <Box flex={4}>
                <Tabs
                  value={indexActivo}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  {MENU_OPCIONES
                    .map((opcion, index) => (
                      <Tab
                        key={index}
                        label={opcion.label}
                        className={classes.tab}
                        onClick={() => handleClick(opcion)}
                      />
                    ))}
                </Tabs>
              </Box>
            </Hidden>}
            <Box flex={1} className={classes.userContainer}>
              <Menu
                keepMounted={false}
                open={Boolean(userAnchor)}
                anchorEl={userAnchor}
                onClose={() => setUserAnchor(null)}
                transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              >
                <MenuItem onClick={cerrarSesion}>Cerrar sesión</MenuItem>
              </Menu>
              <div className={classes.iconoContainer}>
                <Link to="/chat/conversaciones">
                  <IconButton>
                    <Badge color="secondary" badgeContent={notificaciones.length}>
                      <AiOutlineBell color={paleta.bar.icono} />
                    </Badge>
                  </IconButton>
                </Link>
              </div>
              <Button
                variant="text"
                className={classes.user}
                onClick={({ currentTarget }) => setUserAnchor(currentTarget)}>
                <Typography>{user.nombre}</Typography>
                <RiArrowDownSFill />
              </Button>
            </Box>
          </Box>
        </Toolbar>
        {!ocultarOpciones && <Hidden mdUp>
          <Toolbar style={{ overflow: 'auto' }}>
            <Box flex={4}>
              <Tabs
                value={indexActivo}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                {MENU_OPCIONES.map((opcion, index) => (
                  <Tab
                    key={index}
                    label={opcion.label}
                    className={classes.tab}
                    onClick={() => handleClick(opcion)}
                  />
                ))}
              </Tabs>
            </Box>
          </Toolbar>
        </Hidden>}
      </MaterialBar>
    </div>
  );
};

export default React.memo(AppBar);
