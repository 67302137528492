import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { IoMdAddCircleOutline } from 'react-icons/io';

import Boton from '../Button';
import TextField from '../TextField';
import Typography from '../Typography';

import styles from './styles';

const CabeceroListado = ({
  titulo,
  subtitulo,
  placeHolder,
  buscar,
  agregar,
  esListado,
  guardar,
  cancelar,
  customStyles,
}) => {
  const classes = styles();

  const [txtBusqueda, setTxtBusqueda] = useState('');
  const [txtBusquedaAnterior, setTxtBusquedaAnterior] = useState('');

  const manejadorKeyDown = (e) => {
    if (e.key === "Enter" && buscar)
      if (txtBusquedaAnterior !== txtBusqueda) {
        buscar(txtBusqueda);
        setTxtBusquedaAnterior(txtBusqueda);
      }
  };

  return (
    <div>
      <Box flex={1} className={classes.root}>
        <div className={classes.titleContainer}>
          <Typography
            component="h2"
            className={classes.title}
            style={customStyles}
          >
            {titulo}
          </Typography>
          <Typography className={classes.subtitle}>{subtitulo}</Typography>
        </div>
        {esListado && (
          <Box flex={1} className={classes.actionContainer}>
            {buscar && (
              <TextField
                name="txtBusqueda"
                placeHolder={placeHolder}
                className={classes.textField}
                inputClassName={classes.textFieldInput}
                onChange={({ target: { value } }) => setTxtBusqueda(value)}
                value={txtBusqueda}
                onKeyDown={manejadorKeyDown}
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
              />
            )}
            {agregar && (
              <Box className={classes.btnContainer}>
                <Boton
                  icono={<IoMdAddCircleOutline size={18} />}
                  label="Agregar"
                  onClick={agregar}
                  classesCustom={{
                    boton: classes.btnAdd,
                  }}
                />
              </Box>
            )}
          </Box>
        )}
        {!esListado && (
          <Box flex={1} className={classes.actionContainer}>
            {buscar && (
              <TextField
                name="txtBusqueda"
                placeHolder={placeHolder}
                className={classes.textField}
                onChange={({ target: { value } }) => setTxtBusqueda(value)}
                value={txtBusqueda}
                onKeyDown={manejadorKeyDown}
              />
            )}
            {guardar && (
              <Boton
                onClick={guardar}
                label="Guardar"
                classesCustom={{ boton: classes.btn }}
              />
            )}
            {cancelar && (
              <Boton
                onClick={cancelar}
                label="Cancelar"
                classesCustom={{ boton: classes.btn }}
                border
              />
            )}
          </Box>
        )}
      </Box>
    </div>
  );
};

CabeceroListado.propTypes = {
  titulo: propTypes.string.isRequired,
  subtitulo: propTypes.string,
  placeHolder: propTypes.string,
  buscar: propTypes.func,
  agregar: propTypes.func,
  esListado: propTypes.bool,
  guardar: propTypes.func,
  cancelar: propTypes.func,
  customStyles: propTypes.object,
  customCabecero: propTypes.node,
};

CabeceroListado.defaultProps = {
  subtitulo: '',
  placeHolder: '',
  buscar: null,
  agregar: null,
  esListado: true,
  guardar: null,
  cancelar: null,
  customCabecero: null,
};

export default React.memo(CabeceroListado);
