import React, { createRef, useCallback } from 'react';
import propTypes from 'prop-types';
import { toast } from 'react-toastify';
import { findPropertysEmpty } from '../utilidades/functions';

const FormProvider = ({ children, onSubmit, onChangeErrors }) => {
  const formulario = createRef(null);
  /** Función que se ejecuta al dar submit al formulario */
  const manejador = useCallback((e) => {
    e.preventDefault();
    const { totalErrors, totalRegex, errors, regex } = findPropertysEmpty(e.target);
    let mensaje = '';
    if (totalErrors > 0)
      mensaje = 'Existen campos requeridos por llenar';
    else if (totalRegex > 0)
      mensaje = 'Existen campos no válidos';
    else
      return onSubmit();

    if (onChangeErrors) onChangeErrors({ ...errors, ...regex });
    toast.warn(mensaje);
  }, [onChangeErrors, onSubmit]);

  return (
    <form onSubmit={manejador} ref={formulario} noValidate>
      {children}
    </form>
  );
};

FormProvider.propTypes = {
  /** Elementos del formulario (inputs) */
  children: propTypes.node.isRequired,
  /** Función para ejecutar al dar submit y validar el formulario */
  onSubmit: propTypes.func.isRequired,
  /** Funcion que se ejecuta para pasar los errores */
  onChangeErrors: propTypes.func,
};

FormProvider.defaultProps = {
  onChangeErrors: null,
};

export default FormProvider;
