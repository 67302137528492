import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  responsiveFontSizes,
} from '@material-ui/core/styles';
import paleta from '../configuraciones/paleta';

let theme = createMuiTheme({
  typography: {
    fontSize: 12,
    fontFamily: '"Avenir", "Helvetica", "Arial", sans-serif',
  },
  overrides: {
    MuiTypography: {
      root: {
        color: paleta.bar.icono,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 10,
        maxHeight: 45,
        height: 45,
        boxShadow: 'none !important',
        textTransform: 'none'
      }
    },
    MuiPaper: {
      root: {
        boxShadow: 'none !important',
      },
      rounded: {
        borderRadius: 8,
      }
    },
    MuiContainer: {
      maxWidthLg: {
        maxWidth: 'inherit !important',
      },
    },
    MuiTabs: {
      indicator: {
        top: 0,
        backgroundColor: paleta.bar.primary,
      },
      root: {
        paddingTop: 1,
        '& .MuiTab-root': {
          color: paleta.bar.secondary,
          textTransform: 'none',
          fontWeight: 600,
        },
        '& .Mui-selected': {
          color: `${paleta.bar.primary} !important`,
        },
      },
    },
    MuiCheckbox: {
      root: {
        '&.Mui-checked': {
          color: `${paleta.bar.user} !important`,
        },
      },
    },
    MuiAutocomplete: {
      root: {
        "& .MuiAutocomplete-inputRoot": {
          minHeight: 45,
          backgroundColor: paleta.textField.backgroundColor,
          borderRadius: 8,
          width: '95%',
          border: `1px solid ${paleta.textField.border}`,
          '&:hover, &:focus': {
            backgroundColor: paleta.textField.backgroundColorHover,
          },
          '&.disabled': {
            backgroundColor: paleta.textField.backgroundColorDisabled,
          },
        },

        "& .MuiInputLabel-outlined.MuiInputLabel-marginDense": {
          transform: 'translate(14px, 15px) scale(1)',
          color: paleta.textField.text,
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
          transform: 'translate(14px, -8px) scale(0.75)',
          color: paleta.textField.text,
        },
        "& .MuiInputBase-root.Mui-focused fieldset": {
          border: 'none',
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: 'none',
        }
      }
    }
  },
});

theme = responsiveFontSizes(theme);

export default theme;