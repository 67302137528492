import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  root: {
    position: 'sticky',
    top: 0,
    left: 0,
    display: 'flex',
    width: 64,
    minWidth: 64,
    height: '100%',
    maxHeight: '100%',
    overflow: 'auto',
    justifyContent: 'flex-start',
    borderRight: `1px solid ${paleta.bar.border}`,
    backgroundColor: paleta.sidebar.backgroundColor,
    '&.mostrarLista' :{
      '&.sidebarIsOpen': {
        width: 390,
        minWidth: 390,
      },
    },
    '&.sidebarIsOpen': {
      width: 230,
      minWidth: 230,
    },
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.standard
    }),
    [theme.breakpoints.only('xs')]: {
      width: 60,
      minWidth: 60,
      '&.mostrarLista' :{
        '&.sidebarIsOpen': {
          width: 380,
          minWidth: 380,
        },
      },
      '&.sidebarIsOpen': {
        width: 180,
        minWidth: 180,
      },
    }
  },
  item: {
    '&:not(.sidebarOpen)': {
      width: 64,
      textAlign: 'center',
    },
    width: 'auto',
    '& span': {
      color: paleta.bar.icono,
      fontWeight: '600',
      fontSize: 12,
      '& p': {
        color: paleta.bar.elements,
        fontSize: 10,
        fontWeight: '500',
      },
    },
    '&.active': {
      backgroundColor: paleta.sidebar.active,
      borderLeft: `3px solid ${paleta.bar.primary}`,
    },
  },
  btnOpenContainer: {
    width: '100%',
    backgroundColor: paleta.sidebar.backgroundColor,
    position: 'sticky',
    top: 0,
    zIndex: 1000000,
    padding: '5px 0px'
  },
  btnOpen: {
    backgroundColor: paleta.textField.backgroundColor,
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.standard
    }),
    '&.sidebarIsOpen': {
      transform: 'rotate(180deg)',
    },
  },
  title: {
    fontSize: 16,
    color: paleta.bar.icono,
    marginRight: 10,
    fontWeight: '600',
  },
  link: {
    textDecoration: 'none',
  },
}));

export default styles;

