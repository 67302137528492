import propTypes from 'prop-types';
import { Paper, makeStyles, useMediaQuery } from '@material-ui/core';
import { useEffect, useState } from 'react';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#F4F9FD',
    padding: 20,
    minHeight: 500,
    height: '100%',
    borderRadius: 24,
  },
}));

const PaperContainer = ({ children, idTamanoPadre, porcentajeAltura }) => {
  const match = useMediaQuery((theme) => theme.breakpoints.only('xs'));
  const [height, setHeight] = useState(0);
  const styles = useStyles();


  useEffect(() => {
    const valor = (document.getElementById(idTamanoPadre)?.clientHeight * porcentajeAltura) || 0;
    setHeight(valor);
  }, [idTamanoPadre, porcentajeAltura]);

  return (
    <Paper className={styles.root} style={{ height: match ? 0 : height }}>
      {children}
    </Paper>
  )
};

PaperContainer.propTypes = {
  porcentajeAltura: propTypes.number,
};

PaperContainer.defaultProps = {
  porcentajeAltura: 0.85,
};

export default PaperContainer;
