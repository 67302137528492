/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useRef } from "react";
import propTypes from 'prop-types';
import queryString from 'query-string';
import Default from "../Default";
import Tabla from "../../componentes/Table";
import endpoints from "../../configuraciones/endpoints";
import axios from "../../configuraciones/axios";
import general from "../../configuraciones/general";
import { useDispatch, useSelector } from "react-redux";
import { setRegistros, setLimpiar } from "../../ducks/listados";
const FORMULARIO = "formulario";

const Listados = ({
  titulo, subtitulo, mostrarLista,
  datosLista, history, location,
  placeHolder, cabeceros, apiKey,
  parametros, customActions, mostrarCabeceroListado,
  mostrarCabeceroFormulario, guardar, cancelar, showActions,
  customClass, rowErrors, customCabecero, filtrosBusqueda,
  onDelete, onEdit, agregar
}) => {
  const dispatch = useDispatch();
  const { registros } = useSelector(store => store.listados);
  const [filtros, setFiltros] = useState({
    txtBusqueda: '',
  });

  const paginaRef = useRef(1);

  const consultarListado = () => {
    axios
      .get(endpoints.base.url(apiKey), {
        params: {
          ...filtros,
          ...parametros,
          registrosPorPagina: general.ELEMENTOS_POR_PAGINA,
          pagina: paginaRef.current,
        },
      })
      .then((e) => {
        if (e.rows.length === 0 && paginaRef.current > 1) {
          history.push({ search: `?pagina=${paginaRef.current - 1}` });
        }
        dispatch(setRegistros(e));
      });
  };

  const buscar = useCallback((txtBusqueda, criterios) => {
    history.replace({ search: `?pagina=${1}` });
    setFiltros((current) => ({ ...current, txtBusqueda, ...criterios }));
  }, [history]);

  const obtenerPath = (id = "") => `${location.pathname}/${FORMULARIO}/${id}`;

  const irAFormulario = useCallback(({ id }) => {
    const query = queryString.parse(window.location.hash.split("?")[1] || "");
    history.push({
      pathname: obtenerPath(id || ""),
      state: { pagina: query.pagina || 1 },
    });
  }, []);

  const eliminar = useCallback(({ id }) => {
    axios.delete(endpoints.base.url(apiKey, id)).then(consultarListado);
  }, []);

  useEffect(() => {
    const query = queryString.parse(location.search);
    paginaRef.current = query.pagina;
    consultarListado();
    return () => {
      dispatch(setLimpiar());
    }
  }, [filtros, location]);

  return (
    <Default
      titulo={titulo}
      placeHolder={placeHolder}
      buscar={buscar}
      guardar={guardar}
      cancelar={cancelar}
      agregar={agregar && irAFormulario}
      subtitulo={subtitulo || `${registros?.count || 0} elemento(s)`}
      mostrarLista={mostrarLista}
      datosLista={datosLista}
      customCabecero={customCabecero}
      mostrarCabeceroListado={mostrarCabeceroListado}
      mostrarCabeceroFormulario={mostrarCabeceroFormulario}
      filtrosCabeceros={filtrosBusqueda}
    >
      <Tabla
        headers={cabeceros}
        rows={registros?.rows || []}
        count={registros?.count || 0}
        onDelete={onDelete && !mostrarCabeceroFormulario && eliminar}
        onEdit={onEdit && !mostrarCabeceroFormulario && irAFormulario}
        moreActions={customActions}
        customClass={customClass}
        showActions={showActions}
        rowErrors={rowErrors}
      />
    </Default>
  );
};

Listados.propTypes = {
  titulo: propTypes.string.isRequired,
  history: propTypes.object.isRequired,
  location: propTypes.object.isRequired,
  placeHolder: propTypes.string.isRequired,
  cabeceros: propTypes.array.isRequired,
  apiKey: propTypes.string.isRequired,
  customStyles: propTypes.object,
  mostrarCabeceroFormulario: propTypes.bool,
  onDelete: propTypes.bool,
  onEdit: propTypes.bool,
  moreActions: propTypes.array,
  parametros: propTypes.object,
  customActions: propTypes.array,
  mostrarCabeceroListado: propTypes.bool,
  guardar: propTypes.func,
  cancelar: propTypes.func,
  showActions: propTypes.bool,
  filtrosBusqueda: propTypes.shape({
    sitio: propTypes.bool,
    invernadero: propTypes.bool,
    nave: propTypes.bool,
  }),
  agregar: propTypes.bool,
};

Listados.defaultProps = {
  customActions: [],
  mostrarCabeceroListado: true,
  mostrarCabeceroFormulario: false,
  guardar: null,
  cancelar: null,
  showActions: false,
  filtrosBusqueda: {
    sitio: false,
    invernadero: false,
    nave: false,
  },
  onEdit: true,
  onDelete: true,
  agregar: true,
};

export default Listados;
