import queryString from 'query-string';

export const SESIONES = '/sesiones';
export const CLIENTES = '/clientes';
export const PLANES = '/planes';

// Stripe
export const STRIPE_CUSTOMERS = '/stripe/customers';
export const STRIPE_INVOICES = '/stripe/invoices';

const endpoints = {
  base: {
    url: (uri, id = '') => `${uri}/${id}`,
    busqueda: (uri, params) => `${uri}/busqueda${queryString.stringify(params)}`,
  },
  iniciarSesion: () => `${SESIONES}/iniciar`,
  chat: (clienteId) => `${CLIENTES}/${clienteId}/mensajes`,
  clientesChat: () => `${CLIENTES}/mensajes`,
  clientesAutocomplete: () => `${CLIENTES}/autocomplete`,
  planesAutocomplete: () => `${PLANES}/autocomplete`,
  pagosPorCliente: () => `${STRIPE_INVOICES}/por-cliente`,
};

export default endpoints;
