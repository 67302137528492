import React, { useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import propTypes from 'prop-types';
import { Paper, IconButton, Checkbox, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import clsx from 'clsx';
import { FiTrash } from 'react-icons/fi';
import { BsPencil } from 'react-icons/bs';

import Confirmacion from '../Confirmacion';
import Typography from '../Typography';
import general from '../../configuraciones/general';
import styles from './styles';

const TablaInfinita = ({
  headers, rows, showActions, onEdit,
  onDelete, moreActions, hideDelete, showCheckbox,
  onSelectAll, onSelect, customStyles, customClass,
  cellStyles, rowErrors, emptyMessage, loadingThreshold,
  hasNextPage, isNextPageLoading, loadNextPage, bodyRowHeight,
}) => {
  const classes = styles();
  const [anchorConfirmacion, setAnchorConfirmacion] = useState(null);
  const [itemSelected, setItemSelected] = useState(null);

  const customDelete = (event, row, index) => {
    setItemSelected({ ...row, index });
    setAnchorConfirmacion(event.currentTarget);
  };

  const cancelDelete = () => {
    setAnchorConfirmacion(null);
    setItemSelected(null);
  };

  const acceptDelete = () => {
    onDelete({ ...itemSelected });
    cancelDelete();
  };

  // Si hay más info a cargar agrega un renglón extra para mostrar la leyenda "Cargando"
  const itemCount = hasNextPage ? rows.length + 1 : rows.length;

  // Valida si ya se está ejecutando la consulta para que solo pueda levantar una al mismo tiempo
  const loadMoreItems = isNextPageLoading ? () => { } : loadNextPage;

  // Localiza el renglón "Cargando"
  const isItemLoaded = (index) => (!hasNextPage || index < rows.length);

  // Carga cada renglon y el indicador de carga
  const Row = ({ index, style }) => {
    if (!isItemLoaded(index)) {
      return (
        <TableRow
          component="div"
          style={{
            ...style,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography component="p" className={classes.labelSinRegistros}>
            Cargando . . .
          </Typography>
        </TableRow>
      );
    } else {
      return (
        <TableRow
          key={index}
          component="div"
          style={{
            ...style,
            display: 'flex',
            flex: '1 0 auto',
            alignItems: 'center',
          }}
          className={clsx(classes.rowHover, {
            [classes.rowError]: rowErrors.includes(index),
          })}
        >
          {
            showCheckbox &&
            <TableCell
              component="div"
              style={{ textAlign: 'center' }}
            >
              <Checkbox
                checked={rows[index].isSelected}
                value={rows[index].isSelected}
                onClick={() => onSelect(rows[index], index)}
              />
            </TableCell>
          }
          {
            headers.map((head, hIndex) => (
              <TableCell
                key={hIndex}
                component="div"
                style={{
                  flex: '1 0 auto',
                  width: head.width,
                  textAlign: head.align || 'left',
                  border: (itemCount === index + 1) && 'none',
                  ...cellStyles,
                }}
              >
                {!head.transform ?
                  <Typography>{rows[index][head.key] && rows[index][head.key] !== '' ? rows[index][head.key] : '- - -'}</Typography>
                  :
                  <Typography>
                    {head.transform(rows[index], index)}
                  </Typography>
                }
              </TableCell>
            ))
          }
          {
            showActions && (
              <TableCell
                component="div"
                className={classes.actions}
                style={{ border: (itemCount === index + 1) && (itemCount === general.ELEMENTOS_POR_PAGINA) && 'none' }}
              >
                {
                  moreActions.map(({ onClick, icon, validate, transform }, mIndex) => (
                    <div key={mIndex}>
                      {(!validate || validate(rows[index])) && (
                        <>
                          {
                            icon ?
                              <IconButton
                                key={mIndex}
                                size="small"
                                className={clsx(classes.btnAdd, classes.btnAction)}
                                onClick={() => onClick(rows[index], index)}>
                                {icon}
                              </IconButton> :
                              <>
                                {transform(rows[index], index)}
                              </>
                          }
                        </>
                      )}
                    </div>
                  ))
                }
                {
                  onEdit &&
                  <IconButton
                    size="small"
                    className={clsx(classes.btnAdd, classes.btnAction)}
                    onClick={() => onEdit(rows[index], index)}>
                    <BsPencil />
                  </IconButton>
                }
                {
                  onDelete && (!hideDelete || !hideDelete(rows[index])) &&
                  <IconButton
                    size="small"
                    className={clsx(classes.btnAdd, classes.btnAction)}
                    onClick={(e) => customDelete(e, rows[index], index)}>
                    <FiTrash />
                  </IconButton>
                }
              </TableCell>
            )
          }
        </TableRow>
      )
    }
  };

  return (
    <TableContainer component={Paper} className={clsx(classes.root, customClass)} style={customStyles}>
      <Confirmacion
        anchor={anchorConfirmacion}
        onClose={cancelDelete}
        onAccept={acceptDelete}
      />
      <Table
        component="div"
        stickyHeader
        className={classes.table}
      >
        <TableHead component="div">
          <TableRow component="div">
            {
              showCheckbox &&
              <TableCell
                component="div"
                style={{
                  textAlign: 'center',
                }}
              >
                <Checkbox
                  checked={rows.every(({ isSelected }) => isSelected)}
                  value={rows.every(({ isSelected }) => isSelected)}
                  onClick={() => onSelectAll(rows.every(({ isSelected }) => isSelected))}
                />
              </TableCell>
            }
            {
              headers.map((head, index) => (
                <TableCell
                  key={index}
                  component="div"
                  style={{
                    width: head.width,
                    textAlign: head.align || 'left',
                  }}
                  className={head.className || ''}
                >
                  <Typography className={classes.label}>
                    {head.label}
                  </Typography>
                </TableCell>
              ))
            }
            {
              showActions &&
              <TableCell component="div">
                <Typography className={classes.label}>
                  Opciones
                </Typography>
              </TableCell>
            }
          </TableRow>
        </TableHead>
        <TableBody component="div">
          <AutoSizer>
            {({ height, width }) => (
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={itemCount}
                loadMoreItems={loadMoreItems}
                threshold={loadingThreshold}
              >
                {({ onItemsRendered, ref }) => {
                  if (rows.length <= 0) {
                    return <TableRow
                      component="div"
                      style={{
                        width,
                        borderBottom: 'none',
                        position: 'absolute',
                        top: '50%',
                      }}
                    >
                      <Typography
                        component="p"
                        className={classes.label}
                        style={{ textAlign: 'center' }}
                      >
                        {emptyMessage}
                      </Typography>
                    </TableRow>
                  } else {
                    return <List
                      height={height}
                      width={width}
                      itemCount={itemCount}
                      itemSize={bodyRowHeight}
                      onItemsRendered={onItemsRendered}
                      ref={ref}
                    >
                      {Row}
                    </List>
                  }
                }}
              </InfiniteLoader>
            )}
          </AutoSizer>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

TablaInfinita.propTypes = {
  /** Cabeceros de la tabla */
  headers: propTypes.arrayOf(propTypes.shape({
    /** Titlo del cabecero */
    label: propTypes.string.isRequired,
    /** Nombre de la variable para acceder a la información que le corresponda a la columna */
    key: propTypes.string,
    /** Funcion para mostrar un contenido personalizado para los registros de la columna */
    transform: propTypes.func,
    /** Valor para las propiedades text-align de la columna */
    align: propTypes.string,
  })),
  /** Información para llenar la tabla */
  rows: propTypes.array,
  /** Muestra la columna de acciones */
  showActions: propTypes.bool,
  /** Evento para la accion Editar */
  onEdit: propTypes.oneOfType([propTypes.func, propTypes.bool]),
  /** Evento para la accion Borrar */
  onDelete: propTypes.oneOfType([propTypes.func, propTypes.bool]),
  /** Acciones adicionales */
  moreActions: propTypes.array,
  /** Flag para esconder el boton Borrar */
  hideDelete: propTypes.func,
  /** Flag para mostrar la columna de selección con checkbox */
  showCheckbox: propTypes.bool,
  /** Evento para el checkbox de seleccionar todo */
  onSelectAll: propTypes.func,
  /** Evento para la seleccion de checkbox de un registro */
  onSelect: propTypes.func,
  /** Estilos personalizados para la tabla */
  customStyles: propTypes.object,
  /** Estilos personalizados para las celdas */
  cellStyles: propTypes.object,
  /**  */
  rowErrors: propTypes.array,
  /** Mensaje a mostrar cuando no hay registros */
  emptyMessage: propTypes.string,
  /** Indica a los cuantos renglones antes del final de la tabla mandara a traer el siguiente set de registros */
  loadingThreshold: propTypes.number.isRequired,
  /** Indica si quedan más registros por cargar  */
  hasNextPage: propTypes.bool.isRequired,
  /** Indica si ya se está ejecutando la consulta del siguiente set de registros */
  isNextPageLoading: propTypes.bool.isRequired,
  /** Funcion que consulta el siguiente set de registros */
  loadNextPage: propTypes.func,
  /** Altura de los renglones del cuerpo de la tabla en pixeles */
  bodyRowHeight: propTypes.number.isRequired,
};

TablaInfinita.defaultProps = {
  headers: [],
  rows: [],
  showActions: true,
  onEdit: null,
  onDelete: null,
  moreActions: [],
  hideDelete: null,
  showCheckbox: false,
  onSelectAll: null,
  onSelect: null,
  rowErrors: [],
  emptyMessage: 'NO HAY REGISTROS PARA MOSTRAR',
  loadingThreshold: 5,
  hasNextPage: false,
  isNextPageLoading: false,
  loadNextPage: null,
  bodyRowHeight: 38,
};

export default React.memo(TablaInfinita);
