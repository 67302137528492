import { useState, useEffect } from 'react';
import io from 'socket.io-client';

let socket;

const useSocket = (clienteId) => {
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    if (!socket) {
      socket = io(process.env.REACT_APP_URL_SOCKET, {
        query: {
          clienteId,
        },
      });
    }

    if (socket) {
      setConnected(true);
    }
  }, [clienteId]);

  useEffect(() => {
    if (connected) {
      socket.emit('joinRoom', `cliente-${clienteId}`);
    }
  }, [connected, clienteId]);

  return socket;
};

export default useSocket;
