import React, { useCallback, useMemo } from 'react';
import { BiRightArrowAlt } from 'react-icons/bi';
import moment from 'moment';
import Estatus from '../../componentes/Estatus';
import Listados from '../../contenedores/Listados';
import { CLIENTES } from '../../configuraciones/endpoints';


const Clientes = ({ history, location }) => {
  const formatoFecha = 'DD MMM YYYY';
  const cabeceros = useMemo(() => [
    { label: 'ID', key: 'id' },
    { label: 'Cliente', key: 'nombre' },
    { label: 'Plan', transform: ({ suscripcions }) => suscripcions[0].plan.nombre },
    {
      label: 'Vigencia',
      transform: ({ suscripcions }) =>
        `${moment(suscripcions[0].fechaInicio).format(formatoFecha)} - ${moment(suscripcions[0].fechaFin).format(formatoFecha)}`,
    },
    {
      label: 'Estado',
      transform: ({ suscripcions }) =>
        <Estatus
          label={suscripcions[0].estatus.nombre}
          backgroundColor={suscripcions[0].estatus.color}
        />,
    },
  ], []);

  const onClick = useCallback((e) => {
    const { pathname } = location;
    history.push(`${pathname}/${e.id}`);
  }, [history, location]);

  const acciones = useMemo(() => [
    { icon: <BiRightArrowAlt />, onClick },
  ], [onClick]);

  return <Listados
    titulo="Clientes"
    placeHolder="Búsqueda por nombre"
    history={history}
    location={location}
    showActions
    customActions={acciones}
    cabeceros={cabeceros || []}
    apiKey={CLIENTES}
    onEdit={false}
    onDelete={false}
    agregar={false}
  />;
};

export default Clientes;