import propTypes from 'prop-types';
import { makeStyles, Grid, Box } from '@material-ui/core';
import Typography from '../Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: '1px solid #E6EBF5',
  },
  avatarContainer: {
    width: 55,
    height: 55,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '50%',
    '& img': {
      width: '100%',
      height: 'auto',
    }
  },
  nombre: {
    fontWeight: 600,
    fontSize: 14,
  },
  correo: {
    color: '#91929E',
  },
}));
const Header = ({ avatar, nombre, correo }) => {
  const styles = useStyles();

  return (
    <Grid container className={styles.root} alignItems="center">
      <Grid item xs="auto">
        <Box className={styles.avatarContainer}>
          <img
            alt="avatar"
            src={avatar || '/assets/logo.svg'} />
        </Box>
      </Grid>
      <Grid item xs="auto" style={{ marginLeft: 20 }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={styles.nombre}>{nombre}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={styles.correo}>{correo}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

Header.propTypes = {
  /** Direccion (url) del avatar */
  avatar: propTypes.string.isRequired,
};

export default Header;
