import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Default from '../../contenedores/Default';
import { setOpcionActiva } from '../../ducks/appbar';
import styles from './styles';

const Dashboard = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const { indexActivo } = useSelector(store => store.appbar);

  useEffect(() => {
    dispatch(setOpcionActiva(indexActivo === 0 ? 2 : indexActivo));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Default>
      <div className={classes.root}>
        <img
          alt="logo"
          height="auto"
          width={'80%'}
          src="/assets/logo.svg"
          className={classes.img}
        />
      </div>
    </Default>
  );
}

export default Dashboard;
