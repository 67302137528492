import { makeStyles } from '@material-ui/core/styles';
import paleta from '../../configuraciones/paleta'

const tabHeight = '40px'; // default: '48px'

const styles = makeStyles(() => ({
  tabsRoot: {
    backgroundColor: paleta.tabControl.backColor,
    borderRadius: '30px',
    padding: '0 4px',
    maxWidth: '650px !important',
    minHeight: `${tabHeight} !important`,
    height: `${tabHeight} !important`,

    '& .MuiButtonBase-root': {
      padding: '3px 4px',
    },

    '& .Mui-selected': {
      backgroundColor: 'transparent',
      color: `${paleta.tabControl.textHighlight} !important`,
      fontWeight: 'bold',
      zIndex: 2,
    },
  },
  tabRoot: {
    minHeight: tabHeight,
    height: tabHeight,
  },
  indicator: {
    backgroundColor: `${paleta.tabControl.tabHighlight} !important`,
    borderRadius: '50px',
    height: '80% !important',
    bottom: '4px !important',
    zIndex: 1,
  },
}));

export default styles;