import propTypes from 'prop-types';
import { makeStyles, Grid, Box } from '@material-ui/core';
import moment from 'moment';
import Typography from '../Typography';

const useStyles = makeStyles(() => ({
  root: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: '100%',
      height: 'auto',
    },
  },
  nombre: {
    color: '#0A1629',
    marginRight: 10,
    fontSize: 14,
  },
  hora: {
    color: '#7D8592'
  },
}));

const Mensaje = ({ mensaje }) => {
  const styles = useStyles();

  return (
    <Grid container>
      <Grid item xs={1}>
        <Box className={styles.root}>
          <img
            alt="avatar"
            src={mensaje.tipoMensaje === 'S' ? '/assets/logo.svg' : (mensaje.cliente?.logo || '/assets/logo.svg')}
          />
        </Box>
      </Grid>
      <Grid item xs={11}>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={styles.nombre}>
              {mensaje.tipoMensaje === 'S' ? 'ProvettaSoft' : mensaje.cliente?.nombre}
            </Typography>
            <Typography className={styles.hora}>{moment(mensaje.fechaRegistro || '').format('dddd DD MMMM YYYY hh:mm A')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              {mensaje.mensaje}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

Mensaje.propTypes = {
  /** Mensaje a mostrar */
  mensaje: propTypes.string.isRequired,
};

export default Mensaje;
