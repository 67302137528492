import propTypes from 'prop-types';
import {
  makeStyles, Box, Grid,
  Button,
} from '@material-ui/core';
import Typography from '../Typography';

const useStyles = makeStyles(() => ({
  root: {
    width: '100% !important'
  },
  mensaje: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    border: '2px solid #FFF',
  },
  btn: {
    width: '100%',
    maxHeight: '200px !important',
    height: 'auto !important',
    padding: '10px !important',
    borderRadius: 14,
    backgroundColor: '#EAF0F6',
    '&:nth-child(2n)': {
      backgroundColor: '#FFFFFF',
    },
  },
}));

const Burbuja = ({ cliente, onClick }) => {
  const styles = useStyles();

  return (
    <Button variant="text"
      className={styles.btn}
      onClick={() => onClick(cliente)}
    >
      <Box className={styles.root}>
        <Grid container spacing={0} style={{ textAlign: 'left' }}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={11}>
                <Typography>{cliente.id}</Typography>
              </Grid>
              <Grid item xs={1}>
                <div
                  className={styles.mensaje}
                  style={{
                    backgroundColor: cliente.notificacion !== '1' ? '#EAF0F6' : '#0AC947',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography>{cliente.nombre}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>{cliente.subDominio}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Button>
  )
};

Burbuja.propTypes = {
  /** Datos del cliente */
  cliente: propTypes.object.isRequired,
  /** Función cuando da clic en la burbuja */
  onClick: propTypes.func.isRequired,
};

export default Burbuja;
