import propTypes from 'prop-types';
import { makeStyles, Grid } from '@material-ui/core';
import Typography from '../Typography';
import PaperContainer from '../PaperContainer';

const useStyles = makeStyles(() => ({
  label: {
    color: '#91929E',
  },
  img: {
    width: '100%'
  }
}));

const DetalleSuscripcion = ({ elementos, datos, idTamanoPadre }) => {
  const styles = useStyles();
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <PaperContainer idTamanoPadre={idTamanoPadre}>
          <Grid container spacing={4}>
            {elementos.map((elemento, i) => (
              <Grid item xs={12} key={i}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography className={styles.label}>{elemento.label}</Typography>
                  </Grid>
                  {
                    elemento.label === 'Logo' ?
                      <Grid item xs={12}>
                        <img className={styles.img} src={datos[elemento.key] || "/assets/logo.svg"} alt='icono' />
                      </Grid>
                    :
                      <Grid item xs={12}>
                        <Typography>{datos[elemento.key]}</Typography>
                      </Grid>
                  }
                </Grid>
              </Grid>
            ))}
          </Grid>
        </PaperContainer>
      </Grid>
    </Grid>
  )
};

DetalleSuscripcion.propTypes = {
  elementos: propTypes.arrayOf(propTypes.shape({
    /** Texto a mostrar */
    label: propTypes.string.isRequired,
    /** Propiedad del objeto donde se encuentra el valor a mostrar */
    key: propTypes.string.isRequired,
  })),
  /** Objeto que contiene la información a mostrar */
  datos: propTypes.object,
  /** identificador de un elemento para calcular el tamaño */
  idTamanoPadre: propTypes.string,
};

export default DetalleSuscripcion;
