import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  textField: {
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  textFieldInput: {
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export default styles;
