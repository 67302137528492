/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import Default from '../../contenedores/Default';
import PaperListado from '../../componentes/PaperListado';
import { TabsHeader } from '../../componentes/TabControl';
import ClienteSuscripcion from '../../componentes/ClienteSuscripcion';
import ClientePagos from '../../componentes/ClientePagos';
import Chat from '../../componentes/Chat';
import Typography from '../../componentes/Typography';
import axios from '../../configuraciones/axios';
import useSocket from '../../hooks/useSocket';
import endpoints, { CLIENTES } from '../../configuraciones/endpoints';

const Cliente = ({ history, match }) => {
  const { params: { id } } = match;
  const socket = useSocket();
  const [cliente, setCliente] = useState({ suscripcions: [] });
  const [indexActivo, setIndexActivo] = useState(0);
  const tabs = useMemo(() => [
    { id: 0, label: 'Suscripción', children: <ClienteSuscripcion cliente={cliente} /> },
    { id: 1, label: 'Pagos', children: <ClientePagos cliente={cliente} idTamanoPadre="main" /> },
    // { id: 2, label: 'Historico', children: <span>Historico</span> },
    { id: 2, label: 'Chat', children: <Chat idTamanoPadre="main" cliente={cliente} tipoMensaje="S" /> },
  ], [cliente]);
  const elementosListado = useMemo(() => ([
    { label: 'ID Cliente', key: 'id' },
    { label: 'Nombre cliente', key: 'nombre' },
    { label: 'Teléfono de Contacto', key: 'telefono' },
    { label: 'Email', key: 'correo' },
    { label: 'Logo', key: 'logo' },
  ]), []);


  const consultarDatosIniciales = () => new Promise((resolve, reject) => {
    const promesas = [];
    if (id) promesas.push(axios.get(endpoints.base.url(CLIENTES, id)));

    Promise.all(promesas).then((resultadosPromesas) => {
      const [clienteBd] = resultadosPromesas;
      setCliente(clienteBd);
      resolve();
    }).catch(reject);
  });

  useEffect(() => {
    if (indexActivo === 3) {
      socket.emit('visto', `cliente-${cliente.id}`, { tipoMensaje: 'S' });
    }
  }, [indexActivo]);

  useEffect(() => {
    consultarDatosIniciales();
  }, [id]);

  return <Default
    titulo={''}
    placeHolder={''}
    mostrarCabeceroFormulario
  >
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Typography variant="h4">Detalle de suscripción</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <PaperListado elementos={elementosListado} datos={cliente} idTamanoPadre="main" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <TabsHeader tabs={tabs} onChangeActive={setIndexActivo} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Default>;
};

export default React.memo(Cliente);