import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Sidebar from '../../componentes/Sidebar';
import CabeceroListado from '../../componentes/CabeceroListado';
// import useNotificaciones from '../../hooks/notificaciones';
import useSocket from '../../hooks/useSocket';
import { agregarNotificacion } from '../../ducks/user';
import styles from './styles';

const Default = ({
  children,
  mostrarCabeceroListado,
  mostrarCabeceroFormulario,
  customCabecero,
  titulo,
  subtitulo,
  placeHolder,
  buscar,
  agregar,
  guardar,
  cancelar,
  style,
  mostrarLista,
  filtrosCabeceros,
}) => {
  const classes = styles();
  // useNotificaciones();

  const dispatch = useDispatch();
  const socket = useSocket('LAB');

  useEffect(() => {
    if (!socket) return;
    socket.on('mensaje_recibido', (data) => {
      dispatch(agregarNotificacion(data));
    });
  }, [dispatch, socket]);


  return (
    <div className={classes.root}>
      <Sidebar mostrarLista={mostrarLista} />
      <main className={classes.main} id="main">
        {mostrarCabeceroListado && <CabeceroListado
          titulo={titulo}
          subtitulo={subtitulo}
          placeHolder={placeHolder}
          buscar={buscar}
          agregar={agregar}
          customCabecero={customCabecero}
          esListado={mostrarCabeceroListado && !mostrarCabeceroFormulario}
          guardar={guardar}
          cancelar={cancelar}
          customStyles={style}
          filtrosCabeceros={filtrosCabeceros}
        />}
        {children}
      </main>
    </div>
  );
};

Default.propTypes = {
  children: propTypes.node.isRequired,
  mostrarCabeceroListado: propTypes.bool,
  mostrarCabeceroFormulario: propTypes.bool,
  titulo: propTypes.string,
  subtitulo: propTypes.string,
  placeHolder: propTypes.string,
  buscar: propTypes.func,
  agregar: propTypes.func,
  guardar: propTypes.func,
  cancelar: propTypes.func,
  datosLista: propTypes.object,
  filtrosCabeceros: propTypes.shape({
    sitio: propTypes.bool,
    invernadero: propTypes.bool,
    nave: propTypes.bool,
  }),
};

Default.defaultProps = {
  mostrarCabeceroListado: false,
  mostrarCabeceroFormulario: false,
  titulo: "",
  subtitulo: "",
  placeHolder: "",
  buscar: null,
  agregar: null,
  guardar: null,
  cancelar: null,
  datosLista: {},
  mostrarLista: false,
  filtrosCabeceros: {},
};

export default React.memo(Default);
